export const englishLabels = {
    title: "PART I: HEALTH PROFESSIONAL INFORMATION",
    indications: "1 | INDICATIONS",
    pediatrics: "1.1 | PEDIATRICS",
    geriatrics: "1.2 | GERIATRICS",
    contraindications: "2 | CONTRAINDICATIONS",
    seriousWarningsAndPrecautionsBox: "3 | SERIOUS WARNINGS AND PRECAUTIONS BOX",
    dosageAndAdministration: "4 | DOSAGE AND ADMINISTRATION",
    dosingConsiderations: "4.1 | DOSING CONSIDERATIONS",
    recommendedDoseAndDosageAdjustments: "4.2 | RECOMMENDED DOSE AND DOSAGE ADJUSTMENTS",
    reconstituition: "4.3 | RECONSTITUTION",
    administration: "4.4 | ADMINISTRATION",
    missedDose: "4.5 | MISSED DOSE",
    imageAcquisitionAndInterpretation: "4.6 | IMAGE AQUISITION AND INTERPRETATION",
    instructionsForPreparationAndUse: "4.7 | INSTRUCTIONS FOR PREPARATION AND USE",
    radiationDosimetry: "4.8 | RADIATION DOSIMETRY",
    overdosage: "5 | OVERDOSAGE",
    dosageFormsStrengthsCompositionAndPackaging: "6 | DOSAGE FORMS, STRENGTHS, COMPOSITION, AND PACKAGING",
    physicalCharacteristics: "6.1 | PHYSICAL CHARACTERISTICS",
    externalRadiation: "6.2 | EXTERNAL RADIATION",
    warningsAndPrecautions: "7 | WARNINGS AND PRECAUTIONS",
    specialPopulations: "7.1 | SPECIAL POPULATIONS",
    pregnantWomen: "7.1.1 | PREGNANT WOMEN",
    breastFeeding: "7.1.2 | BREAST-FEEDING",
    pediatricsWarningsAndPrecautions: "7.1.3 | PEDIATRICS",
    geriatricsWarningsAndPrecautions: "7.1.4 | GERIATRICS",
    adverseReactions: "8 | ADVERSE REACTIONS",
    adverseReactionsOverview: "8.1 | ADVERSE REACTION OVERVIEW",
    clinicalTrialAdverseReactions: "8.2 | CLINICAL TRIAL ADVERSE REACTIONS",
    clinicalTrialAdverseReactionsPediatrics: "8.2.1 | CLINICAL TRIAL ADVERSE REACTIONS (PEDIATRICS)",
    lessCommonClinicalTrialAdverseReactions: "8.3 | LESS COMMON CLINICAL TRIAL ADVERSE REACTIONS",
    lessCommonClinicalTrialAdverseReactionsPediatrics: "8.3.1 | LESS COMMON CLINICAL TRIAL ADVERSE REACTIONS (PEDIATRICS)",
    abnormalLaboratoryFindingsHematologic: "8.4 | ABNORMAL LABORATORY FINDINGS: HEMATOLOGIC, CLINICAL CHEMISTRY AND OTHER QUANTITATIVE DATA",
    postMarketAdverseReactions: "8.5 | POST-MARKET ADVERSE REACTIONS",
    drugInteractions: "9 | DRUG INTERACTIONS",
    seriousDrugInteractions: "9.1 | SERIOUS DRUG INTERACTIONS",
    drugInteractionsOverview: "9.2 | DRUG INTERACTIONS OVERVIEW",
    drugDrugInteractions: "9.4 | DRUG-DRUG INTERACTIONS",
    drugFoodInteractions: "9.5 | DRUG-FOOD INTERACTIONS",
    drugHerbInteractions: "9.6 | DRUG-HERB INTERACTIONS",
    drugLaboratoryTestInteractions: "9.7 | DRUG-LABORATORY TEST INTERACTIONS",
    drugBehaviouralInteractions: "9.3 | DRUG-BEHAVIOURAL INTERACTIONS",
    actionAndClinicalPharmacology: "10 | CLINCIAL PHARMACOLOGY",
    mechanismOfAction: "10.1 | MECHANISM OF ACTION",
    pharmacodynamics: "10.2 | PHARMACODYNAMICS",
    pharmacokinetics: "10.3 | PHARMACOKINETICS",
    immunogenicity: "10.4 | IMMUNOGENICITY",
    storageStabilityAndDisposal: "11 | STORAGE, STABILITY AND DISPOSAL",
    specialHandlingInstructions: "12 | SPECIAL HANDLING INSTRUCTIONS"
}

export const frenchLabels = {
    title: "PARTIE I: RENSEIGNEMENTS POUR LE PROFESSIONNEL DE LA SANTÉ",
    indications: "1 | INDICATIONS",
    pediatrics: "1.1 | PÉDIATRIE",
    geriatrics: "1.2 | GÉRIATRIE",
    contraindications: "2 | CONTRE-INDICATIONS",
    seriousWarningsAndPrecautionsBox: "3 | ENCADRÉ SUR LES MISES EN GARDE ET PRÉCAUTIONS IMPORTANTES",
    dosageAndAdministration: "4 | POSOLOGIE ET ADMINISTRATION",
    dosingConsiderations: "4.1 | CONSIDÉRATIONS POSOLOGIQUES",
    recommendedDoseAndDosageAdjustments: "4.2 | POSOLOGIE RECOMMANDÉE ET MODIFICATION POSOLOGIQUE",
    reconstituition: "4.3 | RECONSTITUTION",
    administration: "4.4 | ADMINISTRATION",
    missedDose: "4.5 | DOSE OUBLIÉE",
    imageAcquisitionAndInterpretation: "4.6 | ACQUISITION ET INTERPRETATION D'IMAGE",
    instructionsForPreparationAndUse: "4.7 | INSTRUCTIONS POUR LA PRÉPARATION ET L'UTILISATION",
    radiationDosimetry: "4.8 | DOSIMÉTRIE DES RAYONNEMENTS",
    overdosage: "5 | SURDOSAGE",
    dosageFormsStrengthsCompositionAndPackaging: "6 | FORMES PHARMACEUTIQUES, TENEURS, COMPOSITION ET CONDITIONNEMENT",
    physicalCharacteristics: "6.1 | CARACTÉRISTIQUES PHYSIQUES",
    externalRadiation: "6.2 | IRRADIATION EXTERNE",
    warningsAndPrecautions: "7 | MISES EN GARDE ET PRÉCAUTIONS",
    specialPopulations: "7.1 | POPULATIONS PARTICULIÈRES",
    pregnantWomen: "7.1.1 | FEMMES ENCEINTES",
    breastFeeding: "7.1.2 | FEMMES QUI ALLAITENT",
    pediatricsWarningsAndPrecautions: "7.1.3 | ENFANTS ET ADOLESCENTS",
    geriatricsWarningsAndPrecautions: "7.1.4 | PERSONNES ÂGÉES",
    adverseReactions: "8 | EFFETS INDÉSIRABLES",
    adverseReactionsOverview: "8.1 | APERÇU DES EFFETS INDÉSIRABLES",
    clinicalTrialAdverseReactions: "8.2 | EFFETS INDÉSIRABLES OBSERVÉS AU COURS DES ÉTUDES CLINIQUES",
    clinicalTrialAdverseReactionsPediatrics: "8.2.1 | EFFETS INDÉSIRABLES OBSERVÉS AU COURS DES ÉTUDES CLINIQUES – ENFANTS ET ADOLESCENTS",
    lessCommonClinicalTrialAdverseReactions: "8.3 | EFFETS INDÉSIRABLES PEU FRÉQUENTS OBSERVÉS AU COURS DES ÉTUDES CLINIQUES",
    lessCommonClinicalTrialAdverseReactionsPediatrics: "8.3.1 | EFFETS INDÉSIRABLES PEU FRÉQUENTS OBSERVÉS AU COURS DES ÉTUDES CLINIQUES – ENFANTS ET ADOLESCENTS",
    abnormalLaboratoryFindingsHematologic: "8.4 | RÉSULTATS ANORMAUX AUX EXAMENS DE LABORATOIRE : DONNÉES HÉMATOLOGIQUES, DONNÉES BIOCHIMIQUES ET AUTRES DONNÉES QUANTITATIVES",
    postMarketAdverseReactions: "8.5 | EFFETS INDÉSIRABLES OBSERVÉS APRÈS LA COMMERCIALISATION",
    drugInteractions: "9 | INTERACTIONS MÉDICAMENTEUSES",
    seriousDrugInteractions: "9.1 | INTERACTIONS MÉDICAMENTEUSES GRAVES",
    drugInteractionsOverview: "9.2 | APERÇU DES INTERACTIONS MÉDICAMENTEUSES",
    drugBehaviouralInteractions: "9.3 | INTERACTIONS MÉDICAMENT-COMPORTEMENT",
    drugDrugInteractions: "9.4 | INTERACTIONS MÉDICAMENT-MÉDICAMENT",
    drugFoodInteractions: "9.5 | INTERACTIONS MÉDICAMENT-ALIMENT",
    drugHerbInteractions: "9.6 | INTERACTIONS MÉDICAMENT-PLANTE MÉDICINALE",
    drugLaboratoryTestInteractions: "9.7 | INTERACTIONS MÉDICAMENT-ÉPREUVES DE LABORATOIRE",
    actionAndClinicalPharmacology: "10 | PHARMACOLOGIE CLINIQUE",
    mechanismOfAction: "10.1 | MODE D'ACTION",
    pharmacodynamics: "10.2 | PHARMACODYNAMIE",
    pharmacokinetics: "10.3 | PHARMACOCINÉTIQUE",
    immunogenicity: "10.4 | IMMUNOGENICITÉ",
    storageStabilityAndDisposal: "11 | CONSERVATION, STABILITÉ ET MISE AU REBUT",
    specialHandlingInstructions: "12 | PARTICULARITÉS DE MANIPULATION DU PRODUIT"
}