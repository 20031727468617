import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import WysiwygEditor from '../formComponents/WysiwygEditor';
import TextField from '../formComponents/TextField';
import {englishLabels, frenchLabels} from "../../formLabels/PartTwoFormLabels"
import PMButton from "../../common/Button"

import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core"
import { makeStyles} from '@material-ui/core/styles';

import { englishTooltipLabels, frenchTooltipLabels } from "../../tooltipLabels/PartTwoTooltipLabels"
import TooltipComponent from '../formComponents/TooltipComponent'

import { updateFormField } from '../../../actions/forms';
import { SECTION_INDEXES } from '../formUtils/SectionIndexes';

import _ from "lodash"

const useStyles = makeStyles({
    button: {
        width: "50%",
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    }
});

export default function PartTwo({ context }){

    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const currentSectionIndex = useSelector((state) => state.forms.currentSectionIndex)
    const updating = useSelector((state) => state.forms.updating)
    const [labels, setLabels] = useState({})
    const [tooltipLabels, setTooltipLabels] = useState({});
    const classes = useStyles();

    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
        setTooltipLabels(context ? frenchTooltipLabels : englishTooltipLabels)
    }, [context])

    const handleUpdateField = (name, content) => {
        _.set(formInput, name, content)
        dispatch(updateFormField(name, content, form._id, context))
    }

    const handleAddClinicalTrialsByIndications = () => {
        formInput.clinicalTrialsByIndications.push("");
        dispatch(updateFormField("clinicalTrialsByIndications", formInput.clinicalTrialsByIndications, form._id, context))
    }

    const handleDeleteClinicalTrialsByIndications = () => {
        formInput.clinicalTrialsByIndications.pop();
        dispatch(updateFormField("clinicalTrialsByIndications", formInput.clinicalTrialsByIndications, form._id, context))
    }

    const getSectionContent = () => {
        switch(currentSectionIndex){
            case SECTION_INDEXES.PART_TWO:
                return null
            case SECTION_INDEXES.PHARMACEUTICAL_INFORMATION:
                return(
                    <Box key={`pharmaceuticalInformation-${context}`}>
                        <h6>
                            <Box>
                                {labels.pharmaceuticalInformation}
                                <TooltipComponent label={tooltipLabels.pharmaceuticalInformation}/>
                            </Box>
                        </h6>
                        <WysiwygEditor
                            language={context} 
                            initialValue = {formInput.pharmaceuticalInformation} 
                            name = "pharmaceuticalInformation" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.CLINICAL_TRIALS:
                return null
            case SECTION_INDEXES.CLINICAL_TRIALS_BY_INDICATIONS:
                return(
                    <Box>
                        <Box mb={2}>
                            <Grid container>
                                <Grid style={{alignSelf:"center"}}item xs={10}>
                                    <h6>
                                        <Box>
                                            {labels.clinicalTrialsByIndications}
                                            <TooltipComponent label={tooltipLabels.clinicalTrialsByIndications}/>
                                        </Box>
                                    </h6>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box display="flex">
                                        <Box width="100%" mr={2}>
                                            <PMButton isFormButton={true} disabled={updating} onClick={handleAddClinicalTrialsByIndications}>Add</PMButton>
                                        </Box>
                                        <Box width="100%">
                                            <PMButton isFormButton={true} disabled={updating} red onClick={handleDeleteClinicalTrialsByIndications}>Delete</PMButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {formInput.clinicalTrialsByIndications.length > 0 ? (
                            <Box>
                                {formInput.clinicalTrialsByIndications.map((clinicalTrialsByIndication, index) => {
                                    return (
                                        <>
                                            <Box key={`clinicalTrialsByIndications-${index}-${context}`} mb={2}>
                                                <TextField 
                                                    updateFunction={handleUpdateField} 
                                                    placeholder = {`Indication ${index + 1}`}
                                                    name = {`clinicalTrialsByIndicationNames[${index}]`}
                                                />
                                                <WysiwygEditor
                                                    language={context} 
                                                    initialValue = {formInput.clinicalTrialsByIndications[index]} 
                                                    name = {`clinicalTrialsByIndications[${index}]`} 
                                                />
                                            </Box>
                                            {index < formInput.clinicalTrialsByIndications.length - 1 && (
                                                <Box mt={2} width={"50%"} style={{backgroundColor: "black", margin: "0 auto"}}>
                                                    <Divider/>
                                                </Box>
                                            )}

                                        </>
                                        
                                    )
                                })}
                            </Box>
                        ) : (
                            <Box>
                                There are currently no clinical trials by indications
                            </Box>
                        )}
                    </Box>
                )
            case SECTION_INDEXES.COMPARATIVE_BIOAVAILABILTY_STUDIES:
                return(
                    <Box key={`comparativeBioavailabiltyStudies-${context}`}>
                        <h6>
                            <Box>
                                {labels.comparativeBioavailabiltyStudies}
                                <TooltipComponent label={tooltipLabels.comparativeBioavailabiltyStudies} />
                            </Box>
                        </h6>
                        <WysiwygEditor
                            language={context} 
                            initialValue = {formInput.comparativeBioavailabiltyStudies} 
                            name = "comparativeBioavailabiltyStudies" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.CLINICAL_TRIALS_REFERENCE_BIOLOGIC_DRUG:
                return(
                    <Box key={`clinicalTrialsReferenceBiologicDrug-${context}`}>
                        <h6>
                            <Box>
                                {labels.clinicalTrialsReferenceBiologicDrug}
                                <TooltipComponent label={tooltipLabels.clinicalTrialsReferenceBiologicDrug} />
                            </Box>
                        </h6>
                        <WysiwygEditor
                            language={context} 
                            initialValue = {formInput.clinicalTrialsReferenceBiologicDrug} 
                            name = "clinicalTrialsReferenceBiologicDrug" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.MICROBIOLOGY:
                return(
                    <Box key={`microbiology-${context}`}>
                        <h6>
                            <Box>
                                {labels.microbiology}
                                <TooltipComponent label={tooltipLabels.microbiology} />
                            </Box>
                        </h6>
                        <WysiwygEditor
                            language={context} 
                            initialValue = {formInput.microbiology} 
                            name = "microbiology" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.NON_CLINICAL_TOXICOLOGY:
                return(
                    <Box key={`nonClinicalToxicology-${context}`}>
                        <h6>
                            <Box>
                                {labels.nonClinicalToxicology}
                                <TooltipComponent label={tooltipLabels.nonClinicalToxicology} />
                            </Box>
                        </h6>
                        <WysiwygEditor
                            language={context} 
                            initialValue = {formInput.nonClinicalToxicology} 
                            name = "nonClinicalToxicology" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.COMPARATIVE_NON_CLINICAL_PHARMACOLOGY_AND_TOXICOLOGY:
                return null
            case SECTION_INDEXES.COMPARATIVE_NON_CLINICAL_PHARMACODYNAMICS:
                return(
                    <Box key={`comparativeNonClinicalPharmacodynamics-${context}`}>
                        <h6>
                            <Box>
                                {labels.comparativeNonClinicalPharmacodynamics}
                                <TooltipComponent label={tooltipLabels.comparativeNonClinicalPharmacodynamics} />
                            </Box>
                        </h6>
                        <WysiwygEditor
                            language={context} 
                            initialValue = {formInput.comparativeNonClinicalPharmacodynamics} 
                            name = "comparativeNonClinicalPharmacodynamics" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.COMPARATIVE_TOXICOLOGY:
                return(
                    <Box key={`comparativeToxicology-${context}`}>
                        <h6>
                            <Box>
                                {labels.comparativeToxicology}
                                <TooltipComponent label={tooltipLabels.comparativeToxicology} />
                            </Box>
                        </h6>
                        <WysiwygEditor
                            language={context} 
                            initialValue = {formInput.comparativeToxicology} 
                            name = "comparativeToxicology" 
                        />
                    </Box>
                )
            case SECTION_INDEXES.SUPPORTING_PRODUCT_MONOGRAPHS:
                return(
                    <Box key={`supportingProductMonographs-${context}`}>
                        <h6>
                            <Box>
                                {labels.supportingProductMonographs}
                                <TooltipComponent label={tooltipLabels.supportingProductMonographs}/>
                            </Box>
                        </h6>
                        <WysiwygEditor
                            language={context} 
                            initialValue = {formInput.supportingProductMonographs} 
                            name = "supportingProductMonographs" 
                        />
                    </Box>
                )
            default:
                return null
        }
    }

    const Section = getSectionContent()
 
    return(
        <Box mt={4}>
            <Paper className={classes.paper} elevation={4}>
                {Section ?? (
                    <Typography>
                        This section does not need to be filled out.
                    </Typography>
                )}
            </Paper>
        </Box>
    )
}
