export const englishLabels = {
    pageTitle: "TITLE PAGE",
    title: "Title",
    noticeOfCompliance: "Notice of Compliance with Conditions (NOC/c)",
    noticeOfComplianceLabel: "This product has been granted NOC/c market approval, promising evidence of clinical effectiveness following review of the submission by Health Canada.",
    dateOfInitialApproval: "Date of Initial Authorization",
    datePlaceholder: "MM/DD/YYYY",
    controlNumber: "Control Number",
    companyInformation: "Company Information",
    companyName: "Company Name",
    streetAddress: "Street Address",
    city: "City",
    province: "Province",
    postalCode: "Postal Code",
    country: "Country",
    website: "Website",
    footer: "Title Page Footer",
    documentTitle: "Document Title",
}

export const frenchLabels = {
    pageTitle: "PAGE - TITRE",
    title: "Titre",
    noticeOfCompliance: "Avis De Conformité Avec Conditions (AC/C)",
    noticeOfComplianceLabel: "Ce produit a reçu l'approbation de mise en marché AC/C, promettant des preuves d'efficacité clinique suite à l'examen de la soumission par Santé Canada.",
    dateOfInitialApproval: "Date d'approbation initiale",
    datePlaceholder: "MM/DD/YYYY",
    controlNumber: "Numéro de contrôle de la présentation",
    companyInformation: "Informations sur L'enterprise",
    companyName: "Nom De L'enterprise",
    streetAddress: "Adresse De Rue",
    city: "Ville",
    province: "Province",
    postalCode: "Code Postal",
    country: "Pays",
    website: "Site Internet",
    footer: "Pied de page de titre",
    documentTitle: "Titre du document",
}