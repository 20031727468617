import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '../formComponents/TextField'
import TitleEditor from '../formComponents/TitleEditor'
import WysiwygEditor from '../formComponents/WysiwygEditor'
import TooltipComponent from '../formComponents/TooltipComponent'
import TextFieldArray from '../formComponents/TextFieldArray'
import { englishLabels, frenchLabels } from "../../formLabels/TitlePageFormLabels"
import { englishTooltipLabels, frenchTooltipLabels } from "../../tooltipLabels/TitlePageTooltipLabels"

import { makeStyles} from '@material-ui/core/styles';
import { Box, Checkbox, FormControlLabel, Grid, Paper } from '@material-ui/core';
import PMButton from "../../common/Button";

import { contactInfoModel } from '../../../models/Form'
import { updateFormField } from '../../../actions/forms'
import { info } from '../../../utils/SnackBar'
import _ from "lodash"
import { CONTROL_NUMBER_MASK, DATE_MASK } from '../../../utils/utilFunctions'

const useStyles = makeStyles({
    button: {
        width: "50%",
    },
    veritcalText: {
        alignSelf: "center"
    },
    paper: {
        padding: "2rem",
        margin: "2rem 0rem",
        borderRadius: "25px"
    },
});

export default function TitlePage({ context }){

    const dispatch = useDispatch()
    const form = useSelector((state) => state.forms.form)
    const editState = useSelector((state) => state.forms.editState)
    const updating = useSelector((state) => state.forms.updating)
    const classes = useStyles()
    const [labels, setLabels] = useState({});
    const [tooltipLabels, setTooltipLabels] = useState({});

    let formInput = context ? form && form.formDataFrench : form && form.formDataEnglish 

    useEffect(() => {
        setLabels(context ? frenchLabels : englishLabels)
        setTooltipLabels(context ? frenchTooltipLabels : englishTooltipLabels)
    }, [context])

    const handleCreateFormLevelListObj = (name, freeForm = false) => {
        formInput[name].push(contactInfoModel(freeForm))
        dispatch(updateFormField(name, formInput[name], form._id, context))
    }

    const handleDeleteFormLevelListObj = (name, index, freeForm = false) => {
        formInput[name].pop(index)
        if (name === "titlePageCompanyInformations" && formInput[name].length === 0) {
            info("Product Monograph must have at least one company contact")
            formInput[name].push(contactInfoModel(freeForm))
        }
        dispatch(updateFormField(name, formInput[name], form._id, context))
    }

    const handleUpdateField = (name, content) => {
        _.set(formInput, name, content)
        dispatch(updateFormField(name, content, form._id, context))
    }

    return(
        <Box>
            <Paper className={`${classes.paper} section-content`} elevation={4}>
                <Box key={`title-document-${context}`}>
                    <h6>
                        <Box>
                            {labels.documentTitle}
                            <TooltipComponent label={tooltipLabels.documentTitle}/>
                        </Box>
                    </h6>
                    <TitleEditor
                        language={context} 
                        initialValue = {formInput.documentTitle} 
                        name = "documentTitle" 
                    />
                </Box>
                <Box mt={2} key={`title-${context}`} className="document-title">
                    <h6>
                        <Box>
                            {labels.title}
                            <TooltipComponent label={tooltipLabels.title}/>
                        </Box>
                    </h6>
                    <WysiwygEditor 
                        language={context} 
                        initialValue = {formInput.title} 
                        name = "title" 
                    />
                </Box>
            </Paper>
                
            <Paper className={classes.paper} elevation={4}>
                <TextFieldArray
                    updateFunction = {handleUpdateField} 
                    columnStyling = {4}
                    label={[
                        labels.dateOfInitialApproval,
                        <Box>
                            {labels.controlNumber}
                            <TooltipComponent label={tooltipLabels.controlNumber}/>
                        </Box>
                    ]}
                    placeholder = {[labels.datePlaceholder, labels.controlNumber]}
                    inputMask = {[DATE_MASK, CONTROL_NUMBER_MASK]}
                    name = {['dateOfInitialApproval', 'controlNumber']}
                />
            </Paper>

            <Paper className={classes.paper} elevation={4}>
                <h6>{labels.noticeOfCompliance}</h6>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formInput.noticeOfCompliance}
                            disabled={editState.isDisabled}
                            color="primary"
                        />
                    }
                    disabled={editState.isDisabled}
                    name="noticeOfCompliance"
                    label={labels.noticeOfComplianceLabel}
                    onChange={(e) => {handleUpdateField(e.target.name, e.target.checked)}}
                />
            </Paper>

            <Paper className={classes.paper} elevation={4}>
                <Grid container>
                    <Grid xs={10} item className={classes.veritcalText}>
                        <Box>
                            <h6>{labels.companyInformation}</h6>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex">
                            <Box width="100%" mr={2}>
                                <PMButton isFormButton={true} disabled={updating} onClick={()=>{handleCreateFormLevelListObj("titlePageCompanyInformations", true)}}>
                                    Add
                                </PMButton>
                            </Box>
                            <Box width="100%">
                                <PMButton isFormButton={true} disabled={updating} onClick={()=>{handleDeleteFormLevelListObj("titlePageCompanyInformations", formInput.titlePageCompanyInformations.length - 1, true)}} red>
                                    Delete
                                </PMButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {formInput.titlePageCompanyInformations.map( (companyInformation, index) => {
                    return (
                        <Paper key={index} className={classes.paper} elevation={4}>
                            <TextField 
                                updateFunction = {handleUpdateField} 
                                label = {labels.companyName} 
                                placeholder = {labels.companyName} 
                                name = {`titlePageCompanyInformations[${index}].name`}
                            />
                            <TextField 
                                updateFunction = {handleUpdateField} 
                                label = {labels.streetAddress} 
                                placeholder = {labels.streetAddress} 
                                name = {`titlePageCompanyInformations[${index}].streetName`}
                            />
                            <TextFieldArray 
                                updateFunction = {handleUpdateField} 
                                columnStyling = {3} 
                                label = {[labels.city, labels.province, labels.postalCode, labels.country]} 
                                placeholder = {[labels.city, labels.province, labels.postalCode, labels.country]}
                                fieldTypes={[0, 2, 0, 0]}
                                name = {[`titlePageCompanyInformations[${index}].city`, `titlePageCompanyInformations[${index}].state`, `titlePageCompanyInformations[${index}].postalCode`, `titlePageCompanyInformations[${index}].country`]}
                            />
                            <TextField 
                                updateFunction = {handleUpdateField} 
                                label = {labels.website} 
                                placeholder = {labels.website} 
                                name = {`titlePageCompanyInformations[${index}].website`}
                            />
                        </Paper>
                    )
                })}
            </Paper>

            <Paper className={classes.paper} elevation={4}>
                <Box key={`footer-${context}`}>
                    <Box><h6>{labels.footer} <TooltipComponent label={tooltipLabels.footer}/></h6></Box>
                    <WysiwygEditor 
                        language={context} 
                        initialValue = {formInput.footer} 
                        name = "footer" 
                    />
                </Box>
            </Paper>
        </Box>
    )
}
