export const contactInfoModel = (freeForm = false) => {
    return {
        name: freeForm ? '' : {},
        streetName: '',
        city: '',
        state: '',
        postalCode: '',
        country: freeForm ? '' : {},
        telephone: '',
        email: '',
        website: '',
    }
}

export const EmptyRMLC = {
    ENGLISH: "<p>None at time of the most recent authorization.</p>",
    FRENCH: "<p>Aucune au moment de l’autorisation la plus récente.</p>"
}

export const RMLCTemplate = `
    <table ID="TableRMLC" style="height: 29.9844px; width: 99.8387%;">
        <thead class="mceNonEditable">
            <tr style="height: 29.9844px;">
                <th style="width: 71.5539%; height: 29.9844px;">Section</th>
                <th style="width: 28.4461%; height: 29.9844px;">Date</th>
            </tr>
        </thead>
        <tbody style="text-align: left; vertical-align: top;" class="mceEditable">
            <tr>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
`

export const formModel = {

    // Form Metadata
    anchors: {},
    footnotes: [],

    // Title Page
    titlePageEffectiveTime: '',
    title: '',
    documentTitle: '',
    
    titlePageCompanyInformations: [
        contactInfoModel(true)
    ],

    dateOfInitialApproval: '',
    controlNumber: '',

    footer: '',

    // Product Details
    marketAuthorizationHolderName: {},
    marketAuthorizationHolderStreetName: '',
    marketAuthorizationHolderCity: '',
    marketAuthorizationHolderState: '',
    marketAuthorizationHolderPostalCode: '',
    marketAuthorizationHolderCountry: {},
    marketAuthorizationHolderTelephone: '',
    marketAuthorizationHolderEmail: '',
    marketAuthorizationHolderWebsite: '',

    importerDistributors : [],

    productDetailsEffectiveTime: '',

    // Part One: Health Professional Information
    indications: '',
    pediatrics: '',
    geriatrics: '',

    contraindications: '',
    
    seriousWarningsAndPrecautionsBox: '',
    
    dosingConsiderations: '',
    recommendedDoseAndDosageAdjustments: '',
    reconstituition: '',
    administration: '',
    missedDose: '',
    imageAcquisitionAndInterpretation: '',
    instructionsForPreparationAndUse: '',
    radiationDosimetry: '',
    
    overdosage: '',

    dosageFormsStrengthsCompositionAndPackaging: '',
    physicalCharacteristics : '',
    externalRadiation : '',

    warningsAndPrecautions: '',

    pregnantWomen: '',
    breastFeeding: '',
    pediatricsWarningsAndPrecautions: '',
    geriatricsWarningsAndPrecautions: '',

    adverseReactionsOverview: '',
    clinicalTrialAdverseReactions: '',
    clinicalTrialAdverseReactionsPediatrics: '',
    lessCommonClinicalTrialAdverseReactions: '',
    lessCommonClinicalTrialAdverseReactionsPediatrics: '',
    abnormalLaboratoryFindingsHematologic: '',
    postMarketAdverseReactions: '',

    seriousDrugInteractions: '',
    drugInteractionsOverview: '',
    drugBehaviouralInteractions: '',
    drugDrugInteractions: '',
    drugFoodInteractions: '',
    drugHerbInteractions: '',
    drugLaboratoryTestInteractions: '',
    
    mechanismOfAction: '',
    pharmacodynamics: '',
    pharmacokinetics: '',
    immunogenicity: '',                                     // 10.3

    storageStabilityAndDisposal: '',

    specialHandlingInstructions: '',

    // Part Two: Scientific Information
    pharmaceuticalInformation: '',                          // 13
    clinicalTrialsByIndications: [], 
    clinicalTrialsByIndicationNames: [],                    // 14.1
    comparativeBioavailabiltyStudies: '',                   // 14.2
    clinicalTrialsReferenceBiologicDrug: '',                // 14.4
    microbiology: '',                                       // 15
    nonClinicalToxicology: '',                              // 16
    comparativeNonClinicalPharmacodynamics: '',             // 16.1.1
    comparativeToxicology: '',                              // 16.1.2
    supportingProductMonographs: '',                        // 17

    // Part Three: Patient Medication Information
    brandNamePMI: '',
    properName: '',
    readThisForSafeAndEffectiveUseOfYourMedicine: '',
    whatIsBrandNameUsedFor: '',
    howDoesBrandNameWork: '',
    whatAreTheIngredientsInBrandName: '',
    brandNameDosageForms: '',
    doNotUseBrandNameIf: '',
    healthConditionsOrProblems: '',
    otherWarningsYouShouldKnowAbout: '',
    theFollowingMayInteractWithBrandName: '',
    howToTakeBrandName: '',
    usualDose: '',
    overDose: '',
    missedDosePMI: '',
    possibleSideEffects: '',
    storage: '',
    moreInformation: '',

    // Notice of Compliance With Conditions
    noticeOfCompliance: false,

    // Biosimilar Biologic Drug
    biosimilarBiologicDrug: '',
    
    products: [],
    recentMajorLabelChanges: '',
}